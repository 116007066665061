<template>
  <div
    class="card"
  >
    <div class="card-header">
      <h3 class="card-title">
        รายการชนะ วันที่ {{ NewData }} <h3
          class="text-info"
        />
      </h3>
    </div>
    <table class="table table-bordered">
      <colgroup>
        <col width="15%">
        <col width="15%">
        <col width="30%">
        <col width="10%">
        <col width="10%">
        <col width="20%">
      </colgroup>
      <thead>
        <tr>
          <th>เวลา</th>
          <th>ยินดี</th>
          <th>รายละเอียด (ก็อปปี้ไปวางได้เลย)</th>
          <th>ยอดเดิมพัน</th>
          <th>ได้เงิน</th>
          <th>ชื่อบิล</th>
        </tr>
      </thead>
    </table>

    <table
      v-for="item in resdata"
      :key="item"
      class="table table-bordered"
    >
      <colgroup>
        <col width="15%">
        <col width="15%">
        <col width="30%">
        <col width="10%">
        <col width="10%">
        <col width="20%">
      </colgroup>
      <thead>
        <tr>
          <th colspan="6">
            <h5 class="font-weight-bolder">
              {{ item[0].LottoHeadName }}
            </h5>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="dex in item"
          :key="dex"
        >
          <td>{{ moment(dex.updated_at).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss') }}</td>
          <td>ยินดีด้วยค่ะ คุณพี่ถูกรางวัล</td>
          <td>{{ dex.LottoHeadName }} | {{ dex.name_type }} @{{ dex.bet }} {{ dex.amount }}฿ = + {{ Commas(dex.amount * dex.win_rate) }} บาท</td>
          <td>{{ dex.amount }}</td>
          <td>{{ Commas(dex.amount * dex.win_rate) }}</td>
          <td>{{ dex.remark ? dex.remark : 'ไม่ได้ระบุ' }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">
            <h5 class="font-weight-bolder">
              รวมหวยรอบนี้ :
            </h5>
          </td>
          <td>{{ Commas(CalTotal(item.reduce((prev, curr) => prev + (curr.amount * curr.win_rate), 0))) }} ฿</td>
          <td />
        </tr>
      </tfoot>
    </table>
    <table class="table table-bordered">
      <colgroup>
        <col width="15%">
        <col width="15%">
        <col width="30%">
        <col width="10%">
        <col width="10%">
        <col width="20%">
      </colgroup>
      <thead>
        <tr>
          <th>
            <h5 class="font-weight-bolder">
              รวมทุกหวย :
            </h5>
          </th>
          <th />
          <th />
          <th />
          <th>
            <h6 class="font-weight-bolder">
              {{ Commas(total) }} ฿
            </h6>
          </th>
          <th />
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {

  name: 'ResultWin',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resdata: [],
      NewData: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    }
  },
  created() {
    this.interval = setInterval(() => {
      // this.getUserslist()
      this.GetList()
      this.check = null
      this.total = 0
    }, 20000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  async mounted() {
    await this.GetList()
    this.check = null
    this.total = 0
  },
  methods: {
    CalTotal(data) {
      this.total += Number(data)
      return data
    },
    TimeShow(val) {
      const x = moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
      console.log(x)
    },
    async GetList() {
      const obj = {
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/member/history/WinDetailNewV2', obj,
        )
        .then(response => {
          this.Space(response.data.Mes)
          // this.resdata = response.data.Mes
          // console.log(this.resdata)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async Space(data) {
      let i = 0
      const arry = []
      arry.push([])
      // eslint-disable-next-line no-plusplus, no-restricted-syntax
      for await (const item of data) {
        if (item.LottoHeadName !== this.check && this.check !== null) {
          item.LottoSubHead = '1'
          arry.push([])
          // eslint-disable-next-line no-plusplus
          i++
        }
        this.check = item.LottoHeadName
        arry[i].push(item)
        arry[i].sort((a, b) => {
          if (a.created_at > b.created_at) {
            return -1
          }
          if (b.created_at < a.created_at) {
            return 1
          }
          return 0
        })
      }
      this.resdata = arry
      // return this.resdata
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}

</script>
